import axios from 'axios'

export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    data: (state) => state.data,
  },
  actions: {
    async getData({ commit }, filter = {}) {
      const user = JSON.parse(localStorage.getItem('user'))
      const response = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/user`,
        params: filter,
        headers: { authorization: `Bearer ${user.token}` },
      })
      if (response.status === 200) {
        commit('SET_DATA', response.data.result)
      }
    },
    async getDataById({ commit }, id) {
      const user = JSON.parse(localStorage.getItem('user'))
      const response = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/user/${id}`,
        headers: { authorization: `Bearer ${user.token}` },
      })
      if (response.status === 200) {
        commit('SET_DATA', response.data.result)
      }
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
  },
}
