import axios from 'axios'

export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
  },
  actions: {
    async getPercentages({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'))
      const { data, status } = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/percentage`,
        headers: { authorization: `Bearer ${user.token}` },
      })
      if (status === 200) {
        commit('SET_DATA', data.result)
      }
    },
    async getPercentageById({ commit }, id) {
      const user = JSON.parse(localStorage.getItem('user'))
      const { data, status } = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/percentage/${id}`,
        headers: { authorization: `Bearer ${user.token}` },
      })
      if (status === 200) {
        commit('SET_DATA', data.result)
      }
    },
    async postPercentage({ commit }, payload) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data: result, status } = await axios({
          method: 'POST',
          data: { data: payload },
          url: `${process.env.VUE_APP_BASE_URL}/percentage`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        if (status === 200) {
          commit('SET_DATA', [])
        }

        alert(result.message)
      } catch (err) {
        alert(err.response.data.message)
      }
    },
    async updatePercentage({ commit }, { id, payload }) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data: result, status } = await axios({
          method: 'PUT',
          data: { data: payload },
          url: `${process.env.VUE_APP_BASE_URL}/percentage/${id}`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        if (status === 200) {
          commit('SET_DATA', [])
        }

        alert(result.message)
      } catch (err) {
        alert(err.response.data.message)
      }
    },
  },
}
