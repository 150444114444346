exports.nav = [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    level: 1,
  },
  {
    component: 'CNavGroup',
    name: 'Menu QRF',
    to: '/QRF',
    icon: 'cil-list-rich',
    level: 2,
    items: [
      {
        component: 'CNavItem',
        name: 'QRF Waiting Approval',
        to: '/qrf/waiting-approval',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'QRF Approval',
        to: '/qrf/approval',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'QRF Disapproval',
        to: '/qrf/disapproval',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'QRF Expired',
        to: '/qrf/expired',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Menu Quotation',
    to: '/quotation',
    icon: 'cil-list-rich',
    level: 2,
    items: [
      {
        component: 'CNavItem',
        name: 'Waiting Quotation',
        to: '/quotation/waiting',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Close Win Quotation',
        to: '/quotation/win',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Close Lost Quotation',
        to: '/quotation/lost',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Informasi Harga Customer',
    to: '/customer/harga-customer',
    icon: 'cil-list-rich',
    level: 2,
  },
  {
    component: 'CNavGroup',
    name: 'Menu Load License',
    to: '/load-license',
    icon: 'cil-contact',
    level: 2,
    items: [
      {
        component: 'CNavItem',
        name: 'Load License',
        to: '/load-license/list',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Load License Masa Expired',
        to: '/load-license/expired-list',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'End User Data',
    to: '/end-user-data',
    icon: 'cil-contact',
    level: 2,
  },
  {
    component: 'CNavItem',
    name: 'QRF Discount History',
    to: '/qrf-discount-history/list',
    icon: 'cil-list-rich',
    level: 2,
  },
  {
    component: 'CNavItem',
    name: 'Purchase Discount History',
    to: '/purchase-discount-history/list',
    icon: 'cil-list-rich',
    level: 2,
  },
  {
    component: 'CNavItem',
    name: 'Customer',
    to: '/customer/list',
    icon: 'cil-people',
    level: 5,
  },
  {
    component: 'CNavGroup',
    name: 'Pengaturan',
    to: '/pengaturan',
    icon: 'cil-cog',
    level: 5,
    items: [
      {
        component: 'CNavItem',
        name: 'PPN',
        to: '/pengaturan/ppn/list',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Persentase Intensif',
        to: '/pengaturan/persentase-insentif/list',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Approval',
        to: '/pengaturan/approval/list',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'QRF Expired',
        to: '/pengaturan/qrf-expired/list',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Menu Nomor Surat',
    to: '/nomor-surat/list',
    icon: 'cil-truck',
    level: 5,
  },
  {
    component: 'CNavItem',
    name: 'Delivery Order',
    to: '/delivery-order/list',
    icon: 'cil-truck',
    level: 5,
  },
  {
    component: 'CNavGroup',
    name: 'Menu Invoice',
    to: '/invoice',
    icon: 'cil-list-rich',
    level: 5,
    items: [
      {
        component: 'CNavItem',
        name: 'Performa Invoice',
        to: '/performa-invoice/list',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Invoice',
        to: '/invoice/list',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Insentif',
    to: '/insentif',
    icon: 'cil-contact',
    level: 5,
    items: [
      {
        component: 'CNavItem',
        name: 'Perhitungan PO',
        to: '/perhitungan-po/list',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Insentif Per AM',
        to: '/insentif-per-am/list',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Request Harga Khusus',
    to: '/pengaturan/price/list',
    icon: 'cil-contact',
    level: 10,
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]

exports.adminMenu = [
  {
    component: 'CNavItem',
    name: 'Menu Nomor Surat',
    to: '/nomor-surat/list',
    icon: 'cil-truck',
  },
  {
    component: 'CNavItem',
    name: 'Elsa',
    to: '/elsa',
    icon: 'cil-contact',
  },
  {
    component: 'CNavItem',
    name: 'Delivery Order',
    to: '/delivery-order/list',
    icon: 'cil-truck',
  },
  {
    component: 'CNavGroup',
    name: 'Menu Invoice',
    to: '/invoice',
    icon: 'cil-list-rich',
    items: [
      {
        component: 'CNavItem',
        name: 'Performa Invoice',
        to: '/performa-invoice/list',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'Invoice',
        to: '/invoice/list',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Insentif',
    to: '/insentif',
    icon: 'cil-contact',
  },
  {
    component: 'CNavItem',
    name: 'Request Harga Khusus',
    to: '/pengaturan/price/list',
    icon: 'cil-contact',
  },
]
