import axios from 'axios'

export default {
  namespaced: true,
  state: {
    data: [],
    qrfWaitingCount: 0,
    qrfApprovalCount: 0,
    qrfDisapprovalCount: 0,
  },
  getters: {
    data: (state) => state.data,
    qrfApprovalCount: (state) => state.qrfApprovalCount,
    qrfDisapprovalCount: (state) => state.qrfDisapprovalCount,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
    SET_QRF_WAITING_COUNT(state, count) {
      state.qrfWaitingCount = count
    },
    SET_QRF_APPROVAL_COUNT(state, count) {
      state.qrfApprovalCount = count
    },
    SET_QRF_DISAPPROVAL_COUNT(state, count) {
      state.qrfDisapprovalCount = count
    },
  },
  actions: {
    async getQRFWaiting({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_BASE_URL}/qrf/waiting`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        commit('SET_DATA', data.results)
      } catch (err) {
        console.log(err)
      }
    },
    async getQRFCountByStatus({ commit }, status) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await axios({
          method: 'GET',
          params: { status },
          url: `${process.env.VUE_APP_BASE_URL}/qrf/count`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        switch (status) {
          case 0:
            commit('SET_QRF_DISAPPROVAL_COUNT', data.result)
            break
          case 2:
            commit('SET_QRF_APPROVAL_COUNT', data.result)
            break
          case 10:
            commit('SET_QRF_WAITING_COUNT', data.result)
            break
          default:
            break
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getQRFApproval({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_BASE_URL}/qrf/approval`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        commit('SET_DATA', data.results)
      } catch (err) {
        console.log(err)
      }
    },
    async getQRFDisapproval({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_BASE_URL}/qrf/disapproval`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        commit('SET_DATA', data.results)
      } catch (err) {
        console.log(err)
      }
    },
    async getQRFExpired({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_BASE_URL}/qrf/expired`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        commit('SET_DATA', data.results)
      } catch (err) {
        console.log(err)
      }
    },
    async getQRFById({ commit }, id) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_BASE_URL}/qrf/${id}`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        commit('SET_DATA', data.result)
      } catch (err) {
        console.log(err)
      }
    },
  },
}
