<template>
  <router-view />
</template>
<script>
export default {
  name: 'App',
  watch: {
    '$route.name': function (newPath, oldPath) {
      if (newPath === 'Login') {
        sessionStorage.setItem('previousRoute', oldPath)
      } else {
        sessionStorage.setItem('previousRoute', newPath)
      }
    },
  },
}
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
