<template>
  <CHeader class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/#/dashboard"> Dashboard </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <RouterLink to="/change-phone-number">
            <button class="btn border-0">
              <i class="fa-solid fa-mobile-screen-button"></i>
              Nomor HP AM
            </button>
          </RouterLink>
        </CNavItem>
        <CNavItem>
          <RouterLink to="/change-password">
            <button class="btn border-0">
              <i class="fa-solid fa-key"></i>
              Change Password
            </button>
          </RouterLink>
        </CNavItem>
        <CNavItem>
          <button class="btn border-0" @click="logout">
            <i class="fa-solid fa-right-from-bracket"></i> Logout
          </button>
        </CNavItem>
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import { logo } from '@/assets/brand/logo'
import { useRouter } from 'vue-router'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },
  setup() {
    const router = useRouter()

    const logout = () => {
      localStorage.removeItem('user')
      router.push({ name: 'Login' })
    }

    return {
      logo,
      logout,
    }
  },
}
</script>
