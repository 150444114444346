import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/print/qrf/:id',
    name: 'Print QRF',
    component: () => import('@/views/qrf/PrintQRF.vue'),
  },
  {
    path: '/print/quotation/:id',
    name: 'Print Quotation',
    component: () => import('@/views/quotation/PrintQuotationNew.vue'),
  },
  {
    path: '/end-user-data/print/:id',
    name: 'Print End User Data',
    component: () => import('@/views/endUserData/PrintEndUser.vue'),
  },
  {
    path: '/print/delivery-order/:id',
    name: 'Print Delivery Order',
    component: () => import('@/views/deliveryOrder/PrintDeliveryOrder.vue'),
  },
  {
    path: '/print/invoice/:id',
    name: 'Print Invoice',
    component: () => import('@/views/invoice/PrintInvoice.vue'),
  },
  {
    path: '/print/performa-invoice/:id',
    name: 'Print Performa Invoice',
    component: () => import('@/views/invoice/PrintPerformaInvoice.vue'),
  },
  {
    path: '/print/harga/:id/:type',
    name: 'Print Harga',
    component: () => import('@/views/PrintHarga.vue'),
  },
  {
    path: '/end-user/create/:idAm/:token',
    name: 'Create End User Data',
    component: () => import('@/views/endUserData/AddEndUserData.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/qrf',
        name: 'QRF',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/qrf/waiting-approval',
        children: [
          {
            path: '/qrf/waiting-approval',
            name: 'QRF Waiting Approval',
            component: () => import('@/views/qrf/WaitingApproval.vue'),
          },
          {
            path: '/qrf/approval',
            name: 'QRF Approval',
            component: () => import('@/views/qrf/Approval.vue'),
          },
          {
            path: '/qrf/disapproval',
            name: 'QRF Disapproval',
            component: () => import('@/views/qrf/Disapproval.vue'),
          },
          {
            path: '/qrf/expired',
            name: 'Expired',
            component: () => import('@/views/qrf/Expired.vue'),
          },
          {
            path: '/qrf/create/:newAdditional',
            name: 'Create QRF',
            component: () => import('@/views/qrf/CreateQRF.vue'),
          },
          {
            path: '/qrf/create/qdh/:id',
            name: 'Create QRF From QDH',
            component: () => import('@/views/qrf/CreateQRFFromQDH.vue'),
          },
          {
            path: '/qrf/edit/:id',
            name: 'Edit QRF',
            component: () => import('@/views/qrf/EditQRF.vue'),
          },
        ],
      },
      {
        path: '/quotation',
        name: 'Quotation',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/quotation/waiting',
        children: [
          {
            path: '/quotation/waiting',
            name: 'Data Waiting Quotation',
            component: () => import('@/views/quotation/WaitingQuotation.vue'),
          },
          {
            path: '/quotation/win',
            name: 'Data Close Win Quotation',
            component: () => import('@/views/quotation/CloseWin.vue'),
          },
          {
            path: '/quotation/lost',
            name: 'Data Close Lost Quotation',
            component: () => import('@/views/quotation/CloseLost.vue'),
          },
          {
            path: '/quotation/create',
            name: 'Create Quotation',
            component: () => import('@/views/quotation/CreateQuotation.vue'),
          },
          {
            path: '/quotation/edit/:id',
            name: 'Edit Quotation',
            component: () => import('@/views/quotation/EditQuotation.vue'),
          },
          {
            path: '/quotation/add-option/:id',
            name: 'Create Optional Quotation',
            component: () => import('@/views/quotation/AddQuotationOption.vue'),
          },
        ],
      },
      {
        path: '/customer',
        name: 'Customer',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/customer/harga-customer',
        children: [
          {
            path: '/customer/list',
            name: 'Data Customer',
            component: () => import('@/views/customer/CustomerList.vue'),
          },
          {
            path: '/customer/create',
            name: 'Create Customer',
            component: () => import('@/views/customer/CreateCustomer.vue'),
          },
          {
            path: '/customer/edit/:id',
            name: 'Edit Customer',
            component: () => import('@/views/customer/EditCustomer.vue'),
          },
          {
            path: '/customer/harga-customer',
            name: 'Data Informasi Harga Customer',
            component: () => import('@/views/customer/HargaCustomer.vue'),
          },
          {
            path: '/customer/harga-customer/create',
            name: 'Create Informasi Harga Customer',
            component: () => import('@/views/customer/CreateHargaCustomer.vue'),
          },
        ],
      },
      {
        path: '/end-user-data',
        name: 'Data End User Data',
        component: () => import('@/views/endUserData/EndUserDataList.vue'),
      },

      {
        path: '/end-user-data/edit/:id',
        name: 'Edit End User Data',
        component: () => import('@/views/endUserData/EditEndUserData.vue'),
      },
      {
        path: '/qrf-discount-history',
        name: 'QRF Discount History',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/qrf-discount-history/list',
        children: [
          {
            path: '/qrf-discount-history/list',
            name: 'Data QRF Discount History',
            component: () =>
              import('@/views/qrfDiscountHistory/QRFDiscountHistory.vue'),
          },
          {
            path: '/qrf-discount-history/edit/:id',
            name: 'Edit QRF Discount History',
            component: () =>
              import('@/views/qrfDiscountHistory/EditQRFDiscountHistory.vue'),
          },
        ],
      },
      {
        path: '/purchase-discount-history',
        name: 'Purchase Discount History',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/purchase-discount-history/list',
        children: [
          {
            path: '/purchase-discount-history/list',
            name: 'Data Purchase Disount History',
            component: () =>
              import('@/views/purchaseHistory/PurchaseHistoryList.vue'),
          },
          {
            path: '/purchase-discount-history/edit/:id',
            name: 'Edit Purchase Discount History',
            component: () =>
              import('@/views/purchaseHistory/EditPurchaseHistory.vue'),
          },
        ],
      },
      {
        path: '/load-license',
        name: 'Load License',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/load-license/list',
        children: [
          {
            path: '/load-license/list',
            name: 'Load License',
            component: () => import('@/views/loadLicense/LoadLicenseList.vue'),
          },
          {
            path: '/load-license/view/:id',
            name: 'Load License View',
            component: () => import('@/views/loadLicense/LoadLicenseView.vue'),
          },
          {
            path: '/load-license/create/:quoId',
            name: 'Add Load License',
            component: () => import('@/views/loadLicense/AddLoadLicense.vue'),
          },
          {
            path: '/load-license/expired-list',
            name: 'Load License Masa Expired',
            component: () =>
              import('@/views/loadLicense/ExpiredLoadLicense.vue'),
          },
          {
            path: '/load-license/add-optional-end-user/:id',
            name: 'Add Optional End User',
            component: () =>
              import('@/views/loadLicense/AddOptionalEndUser.vue'),
          },
          {
            path: '/load-license/add-optional-file-excel/:id',
            name: 'Add Optional File Excel',
            component: () =>
              import('@/views/loadLicense/AddOptionalFileExcel.vue'),
          },
        ],
      },
      {
        path: '/pengaturan',
        name: 'Pengaturan',
        children: [
          {
            path: '/pengaturan/produk/list',
            name: 'Produk List',
            component: () => import('@/views/pengaturan/produk/ProdukList.vue'),
          },
          {
            path: '/pengaturan/persentase-insentif/list',
            name: 'Persentase Insentif List',
            component: () =>
              import(
                '@/views/pengaturan/persentaseInsentif/PersentaseInsentif.vue'
              ),
          },
          {
            path: '/pengaturan/qrf-expired/list',
            name: 'QRF Expired List',
            component: () =>
              import('@/views/pengaturan/qrfExpired/QRFExpired.vue'),
          },
          {
            path: '/pengaturan/qrf-expired/add',
            name: 'Buat QRF Expired',
            component: () =>
              import('@/views/pengaturan/qrfExpired/AddQRFExpired.vue'),
          },
          {
            path: '/pengaturan/approval/list',
            name: 'Approval QRF List',
            component: () =>
              import('@/views/pengaturan/approval/ApprovalList.vue'),
          },
          {
            path: '/pengaturan/approval/add',
            name: 'Create Approval QRF',
            component: () =>
              import('@/views/pengaturan/approval/CreateApproval.vue'),
          },
          {
            path: '/pengaturan/approval/edit/:id',
            name: 'Edit Approval QRF',
            component: () =>
              import('@/views/pengaturan/approval/EditApproval.vue'),
          },
          {
            path: '/pengaturan/produk/create',
            name: 'Create Produk',
            component: () =>
              import('@/views/pengaturan/produk/CreateProduk.vue'),
          },
          {
            path: '/pengaturan/produk/edit/:id',
            name: 'Edit Produk',
            component: () => import('@/views/pengaturan/produk/EditProduk.vue'),
          },
          {
            path: '/pengaturan/price/list',
            name: 'Price List',
            component: () => import('@/views/pengaturan/price/PriceList.vue'),
          },
          {
            path: '/pengaturan/price/create',
            name: 'Create Price',
            component: () => import('@/views/pengaturan/price/CreatePrice.vue'),
          },
          {
            path: '/pengaturan/price/edit/:id',
            name: 'Edit Price',
            component: () => import('@/views/pengaturan/price/EditPrice.vue'),
          },
          {
            path: '/pengaturan/ppn/list',
            name: 'PPN List',
            component: () => import('@/views/pengaturan/ppn/PPNList.vue'),
          },
          {
            path: '/pengaturan/ppn/create',
            name: 'Create PPN',
            component: () => import('@/views/pengaturan/ppn/CreatePPN.vue'),
          },
          {
            path: '/pengaturan/ppn/edit/:id',
            name: 'Edit PPN',
            component: () => import('@/views/pengaturan/ppn/EditPPN.vue'),
          },
        ],
      },
      {
        path: '/delivery-order',
        name: 'Delivery Order',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/delivery-order/list',
        children: [
          {
            path: '/delivery-order/list',
            name: 'Delivery Order List',
            component: () =>
              import('@/views/deliveryOrder/DeliveryOrderList.vue'),
          },
          {
            path: '/delivery-order/create',
            name: 'Create Delivery Order',
            component: () =>
              import('@/views/deliveryOrder/CreateDeliveryOrder.vue'),
          },
          {
            path: '/delivery-order/edit/:id',
            name: 'Edit Delivery Order',
            component: () =>
              import('@/views/deliveryOrder/EditDeliveryOrder.vue'),
          },
        ],
      },
      {
        path: '/insentif',
        name: 'Insentif',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/insentif/list',
        children: [
          {
            path: '/insentif-per-am/list',
            name: 'Insentif Per AM List',
            component: () =>
              import('@/views/insentif/InsentifPerAM/InsentifPerAM.vue'),
          },
          {
            path: '/perhitungan-po/list',
            name: 'Perhitungan PO List',
            component: () =>
              import('@/views/insentif/PerhitunganPO/PerhitunganPO.vue'),
          },
        ],
      },
      {
        path: '/invoice',
        name: 'Invoice',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/performa-invoice/list',
        children: [
          {
            path: '/performa-invoice/request/:quoid',
            name: 'Request Performa Invoice',
            component: () =>
              import('@/views/invoice/RequestPerformaInvoice.vue'),
          },
          {
            path: '/performa-invoice/list',
            name: 'Performa Invoice List',
            component: () => import('@/views/invoice/PerformaInvoiceList.vue'),
          },
          {
            path: '/performa-invoice/pelunasan/:invoiceId',
            name: 'Pelunasan Performa Invoice',
            component: () =>
              import('@/views/invoice/PelunasanPerformaInvoice.vue'),
          },
          {
            path: '/invoice/pelunasan/:invoiceId',
            name: 'Pelunasan Invoice',
            component: () => import('@/views/invoice/PelunasanInvoice.vue'),
          },
          {
            path: '/invoice/list',
            name: 'Invoice List',
            component: () => import('@/views/invoice/InvoiceList.vue'),
          },
          {
            path: '/invoice/request/:quoid',
            name: 'Request Invoice',
            component: () => import('@/views/invoice/RequestInvoice.vue'),
          },
          {
            path: '/invoice/edit/:id',
            name: 'Edit Invoice',
            component: () => import('@/views/invoice/EditInvoice.vue'),
          },
        ],
      },
      {
        path: '/elsa',
        name: 'Elsa',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/elsa/list',
        children: [
          {
            path: '/elsa/list',
            name: 'Elsa List',
            component: () => import('@/views/elsa/ElsaList.vue'),
          },
          {
            path: '/elsa/view/:id',
            name: 'Elsa View',
            component: () => import('@/views/elsa/ElsaView.vue'),
          },
          {
            path: '/elsa/create',
            name: 'Create Elsa',
            component: () => import('@/views/elsa/CreateElsa.vue'),
          },
        ],
      },
      // NOMOR SURAT
      {
        path: '/nomor-surat',
        name: 'Nomor Surat',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/nomor-surat/list',
        children: [
          {
            path: '/nomor-surat/list',
            name: 'Nomor Surat List',
            component: () => import('@/views/noSurat/NomorSuratList.vue'),
          },
          {
            path: '/nomor-surat/internal/create',
            name: 'Create Nomor Surat Internal',
            component: () =>
              import('@/views/noSurat/CreateNoSuratInternal.vue'),
          },
          {
            path: '/nomor-surat/eksternal/create',
            name: 'Create Nomor Surat Eksternal',
            component: () =>
              import('@/views/noSurat/CreateNoSuratEksternal.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
  },
  {
    path: '/change-password',
    name: 'Change Password',
    component: () => import('@/views/pages/ChangePassword.vue'),
  },
  {
    path: '/change-phone-number',
    name: 'Change Phone Number',
    component: () => import('@/views/pages/ChangePhoneNumber.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const now = new Date().getTime()

  if (!user && to.name !== 'Login' && to.name !== 'Create End User Data') {
    localStorage.removeItem('user')
    return { name: 'Login', query: { redirect: to.fullPath } }
  } else if (
    to.name !== 'Login' &&
    to.name !== 'Create End User Data' &&
    (!user.expiry || now > user.expiry)
  ) {
    localStorage.removeItem('user')
    return { name: 'Login', query: { redirect: to.fullPath } }
  }
})

export default router
