import axios from 'axios'

export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
  },
  actions: {
    async getData({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_CRM_API}/jenis-barang`,
          headers: { authorization: `Bearer ${user.token}` },
        })

        commit('SET_DATA', data.result)
      } catch (err) {
        console.log(err)
      }
    },
  },
}
