import axios from 'axios'

export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
  },
  actions: {
    async getData({ commit }, companyId = '') {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data, status } = await axios({
          method: 'GET',
          params: { companyId },
          url: `${process.env.VUE_APP_CRM_API}/pipeline/list/company`,
          headers: { authorization: `Bearer ${user.token}` },
        })
        if (status === 200) {
          commit('SET_DATA', data.result)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
